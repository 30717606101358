import styles from "./HeaderComponent.module.css";
import teleserviceLogo from "../../../images/skytelLogo.png";

const HeaderComponent = () => {
  return (
    <div className={styles.headerContainer}>
      <img
        className={styles.headerLogo}
        src={teleserviceLogo}
        alt="header logo"
      />
      <div className={styles.headerText}>
        Skytel Teleservices
      </div>
    </div>
  )
}

export default HeaderComponent;